import { GetHomeBuyingJourneyHbj } from '@home-in/models'
import router from 'next/router'
import { Illustrations } from '@elements/icons/illustration'

export const getLenderTileDetails = (hbjToAssignLender: GetHomeBuyingJourneyHbj) => {
  const illustration = Illustrations.homeDollar
  let title = ''
  let message = ''
  const callback = () => router.push(`/add-lender?hbjId=${hbjToAssignLender.hbj_id}`)
  let active = false

  const dummyLender = 'dummylender@cba.com.au'
  const hasChl = !!hbjToAssignLender?.loan?.chl?.length
  const hasLender =
    !!hbjToAssignLender?.loan?.lenderEmail?.length && hbjToAssignLender?.loan?.lenderEmail.toLowerCase() !== dummyLender
  const hasCbaLender = hasLender && !!hbjToAssignLender?.loan?.lenderEmail?.toLowerCase().endsWith('@cba.com.au')

  if (!hasLender) {
    active = true
    title = 'Using your own lender?'
    message =
      'You can add your lender details to help them receive important updates from Home-in and get you settlement ready'
  }

  if (hasCbaLender && !hasChl) {
    active = true
    title = 'Add your CHL number'
    message =
      'Add your home loan application number so that your lender can receive updates from Home-in and get you settlement ready'
  }

  return {
    illustration,
    title,
    message,
    active,
    callback,
  }
}
