import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card } from '@elements/card'
import { LinkStyled } from '@elements/forms/link'
import { Icon, Icons } from '@elements/icons/icon'
import { Modal } from '@elements/modal'
import { trackButtonClick } from '@redux/reducers/analytics'
import { CBA_WORKPLACE_BANKING_OFFER_EXPIRY_DATE } from '@utils/constants/date.constants'
import { AnalyticsCategories, ButtonClickEventNames } from '@utils/helpers/analytics.enum'
import { isOfferExpired, formatDateWithOrdinalSuffix } from '@utils/helpers/date.helpers'

const LearnMoreOfferModalContents = () => {
  return (
    <>
      <div className="mb-2 text-xl font-semibold sm:text-2xl lg:mb-4">Learn More</div>
      <div className="prose max-w-none">
        <p className="text-lg font-semibold">CommBank Workplace Banking Exclusive Offer</p>
        <p>
          <sup>*</sup>You&apos;re eligible for the offer if you:
        </p>
        <ul className="list-none">
          <li>
            <span className="font-semibold">1)</span> Are a CBA Workplace Banking Customer,
          </li>
          <li>
            <span className="font-semibold">2)</span> Sign up for Home-in via the CBA Workplace Banking Portal,
          </li>
          <li>
            <span className="font-semibold">3)</span> Settle on your Home-in property with a CommBank home loan by 30
            June 2025, and
          </li>
          <li>
            <span className="font-semibold">4)</span> Use the in-app legal services provided through Home-in for your
            Home-in property purchase.
          </li>
        </ul>
        <p>
          This offer is only available through CommBank&apos;s proprietary channels, it is not available to customers
          that originate through Bankwest, Unloan or a broker channel.
        </p>
        <p>
          To secure the offer you will need to engage the services of Home-in&apos;s partner law firm via the Home-in
          app. Fulfilment of the offer will be completed by Home-in.
        </p>
        <p>
          Eligible customers will receive either a discount of $1000 off the conveyancing fee or, if your conveyancing
          fee is less than $1000, a waiver of the entire conveyancing fee.
        </p>
        <p>
          Disbursements are additional and charged at cost. Home-in reserves the right to terminate the offer and change
          the pricing at any time. Find out more about Home-in&apos;s pricing by visiting{' '}
          <LinkStyled target="_blank" href="https://www.home-in.com.au/pricing">
            https://www.home-in.com.au/pricing
          </LinkStyled>
          .
        </p>
      </div>
    </>
  )
}

export const CBAWorkPlaceBankingOfferTile = () => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()

  const onClickLearnMore = () => {
    setShowModal(true)
    dispatch(
      trackButtonClick({
        action: ButtonClickEventNames.CBAWorkplaceBankingOffer001TileLearnMore,
        category: AnalyticsCategories.HomeScreen,
      })
    )
  }

  return (
    <>
      <Card size="small">
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Icon asset={Icons.cbaLogo} size={40} />
          <Chip
            label={
              isOfferExpired(CBA_WORKPLACE_BANKING_OFFER_EXPIRY_DATE)
                ? 'Offer Expired'
                : `Expires ${formatDateWithOrdinalSuffix(CBA_WORKPLACE_BANKING_OFFER_EXPIRY_DATE)}`
            }
          />
        </Grid>
        <div className="mb-1 mt-4 font-semibold">CommBank Workplace Banking offer</div>
        <p className="mb-2">
          Save $1000 off your conveyancing fee when you buy with Home-in and settle with a CommBank home loan before 30
          June 2025
          <sup>*</sup>
        </p>
        <a
          onClick={() => onClickLearnMore()}
          className="cursor-pointer text-sm text-primary-700 hover:text-primary-800 sm:text-base"
        >
          Learn more
        </a>
      </Card>
      <Modal size="medium" active={showModal} variant="normal" toggler={() => setShowModal(false)} quickDismiss={true}>
        <LearnMoreOfferModalContents />
      </Modal>
    </>
  )
}
